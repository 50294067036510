
import { Vue, Component } from "vue-property-decorator";
import { jsPDF } from "jspdf";
import { api } from "@/api";
import { readToken, readHasAdminAccess } from "@/store/main/getters";
import {
  readPatientWound, readWoundTasksWithoutComment, readLanguage
} from "@/store/admin/getters";

import {
  IPerspectiveProfileCreate,
  ITaskProfileCreate,
  IWoundUpdate,
} from "@/interfaces";
import { dispatchGetPatients, dispatchSetWound } from "@/store/admin/actions";
import {
  commitSetVisitDate,
  commitSwitchLanguage
} from "@/store/admin/mutations";

@Component
export default class PatientOverview extends Vue {
  public search = "";
  public show = false;
  public showTaskAndPerspectiveTables = false;
  // public visitDate = -1;
  public name = "---";
  public icd = "---";
  public location = "---";
  public items_per_page = [25, 50, 100, {"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}];
  public pagination =  {
    rowsPerPage: 25,
  };

  //For creating tasks and perspective
  public nameTask = "";
  public typeTask = "";
  public descriptionTask = "";
  public dialogTask = false;
  public taskArray = ["TEXT", "TRUEFALSE", "NUMBERSONLY"];
  public searchTasks = "";
  public searchPerspectives = "";

  public dialogPerspective = false;
  public namePerspective = "";
  public descriptionPerspecitve = "";
  public dialogDeletePerspective = false;

  //for editing the wound
  public dialogWound = false;
  public woundICD = "";
  public woundDetails = "";
  public woundLocation = "";
  public woundName = "";
  public updateKey = 0;

  public visitDates = new Set<Date>();

  public headers = [
    {
      text: "Date of Visit",
      sortable: true,
      value: "date",
      align: "left",
    },
  ];
  public headers_german = [
    {
      text: "Datum der Visite",
      sortable: true,
      value: "date",
      align: "left",
    },
  ];

  public headersTasks = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Description",
      sortable: false,
      value: "desription",
      align: "left",
    },
    {
      text: "Type",
      sortable: true,
      value: "type",
      align: "left",
    },
  ];
  public headersTasks_german = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Beschreibung",
      sortable: false,
      value: "desription",
      align: "left",
    },
    {
      text: "Typ",
      sortable: true,
      value: "type",
      align: "left",
    },
  ];

  public headersPerspectives = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Description",
      sortable: false,
      value: "name",
      align: "left",
    },
  ];
  public headersPerspectives_german = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Beschreibung",
      sortable: false,
      value: "name",
      align: "left",
    },
  ];

  get RoutingID() {
    return +this.$router.currentRoute.params.id;
  }

  get nameWound() {
    return this.wound!.name;
  }

  get detailsWound() {
    return this.wound.details;
  }

  get icdWound() {
    return this.wound!.icd;
  }
  
  get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get locationWound() {
    return this.wound!.location;
  }

  get wound() {
    return readPatientWound(this.$store);
  }

  get tasks() {
    return readWoundTasksWithoutComment(this.$store);
  }

  get disableTask() {
    if (this.nameTask != "" && this.typeTask != "") {
      return false;
    } return true;
  }

  get dates() {
    for (var i = 0; i < this.tasks.length; i++) {
      for (var j = 0; j < this.tasks[i].measurement.length; j++) {
        this.visitDates.add(this.tasks[i].measurement[j].creation_date);
      }
    }
    for (var i = 0; i < this.perspectives.length; i++) {
      for (var j = 0; j < this.perspectives[i].pictures?.length; j++) {
        this.visitDates.add(this.perspectives[i].pictures[j]?.date);
      }
    }
    let arr: Date[] = Array.from(this.visitDates);
    let sorted = arr.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    return sorted;
  }
  get perspectives() {
    return this.wound.perspectives;
  }

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public async mounted() {
    this.visitDates.clear();
    dispatchSetWound(this.$store, +this.$router.currentRoute.params.id);
  }

  public setVisitDate(date: Date) {
    commitSetVisitDate(this.$store, date); 
    this.$router
      .push({ name: "main-wounds-visit", params: { id: date.toString() } })
      .catch((error) => console.log(error));
  }

  //For creating the task
  public async saveTask() {
    const task: ITaskProfileCreate = {
      name: this.nameTask,
      type: this.typeTask,
      description: this.descriptionTask,
      wound: this.$router.currentRoute.params.id as any as number,
    };
    await api.createTask(readToken(this.$store), task);
    this.resetTask();
    this.dialogTask = false;
    await dispatchSetWound(this.$store, +this.$router.currentRoute.params.id);
  }

  public async deleteTask(id: number) {
    await api.deleteTask(readToken(this.$store), id);
    await dispatchSetWound(this.$store, +this.$router.currentRoute.params.id);
  }

  public resetTask() {
    this.nameTask = "";
    this.descriptionTask = "";
    this.typeTask = "";
  }

  //For creating the perspective
  public async savePerspective() {
    const pers: IPerspectiveProfileCreate = {
      name: this.namePerspective,
      description: this.descriptionPerspecitve,
      wound: this.$router.currentRoute.params.id as any as number,
    };
    await api.createPerspective(readToken(this.$store), pers);
    this.resetPerspective();
    this.dialogPerspective = false;
    await dispatchSetWound(this.$store, +this.$router.currentRoute.params.id);
  }

  public resetPerspective() {
    this.namePerspective = "";
    this.descriptionPerspecitve = "";
  }

  get disablePerspective() {
    if (this.namePerspective != "") {
      return false;
    }
    return true;
  }

  public dialogWoundIni() {
    this.dialogWound = true;
    this.woundICD = this.wound.icd;
    this.woundLocation = this.wound.location;
    this.woundDetails = this.wound.details;
    this.woundName = this.wound.name;
  }

  public cancel() {
    this.dialogWound = false;
  }
  public async updateWound() {
    var update: boolean = false;
    var updateWound: IWoundUpdate = {
      id: this.wound.id,
    };
    if (this.woundName != this.wound.name) {
      updateWound.name = this.woundName;
      update = true;
    }
    if (this.woundICD != this.wound.icd) {
      updateWound.icd = this.woundICD;
      update = true;
    }
    if (this.woundDetails != this.wound.details) {
      updateWound.details = this.woundDetails;
      update = true;
    }
    if(this.woundLocation != this.wound.location) {
      updateWound.location = this.woundLocation;
      update = true;
    }
    if(update){
      await api.updateWound(readToken(this.$store), this.wound.id, updateWound);
      dispatchGetPatients(this.$store);
      this.updateKey += 1;
      }
    this.dialogWound = false;
  }

  public async deletePerspective(id: number) {
    await api.deletePerspective(readToken(this.$store), id);
    await dispatchSetWound(this.$store, +this.$router.currentRoute.params.id);
  }
  
  public download() {
    console.log("Wound: " + this.wound.name);
    console.log(
      "Here from wound measurement: " + this.wound.tasks[0].measurement
    );
    console.log("Tasks: " + this.tasks);
    console.log("Measurements from Tasks: " + this.tasks[0].measurement);
    console.log("Perspectives: " + this.perspectives);
  }

  public deletePerspectiveQuestion() {
    this.dialogDeletePerspective = true;
  }

  public no() {
    this.dialogDeletePerspective = false;
  }
}
